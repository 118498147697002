body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.App {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.controls-overlay {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-selector {
  flex-grow: 1;
  margin-right: 20px;
}

.date-selector h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.date-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.date-buttons button {
  padding: 5px 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

.date-buttons button.selected {
  background-color: #4caf50;
  color: white;
}

.hotel-search {
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
}

.hotel-search input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 300px;
  font-size: 14px;
}

.hotel-search input::placeholder {
  color: #999;
  font-style: italic;
}

.hotel-search button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.map-container {
  height: calc(100% - 40px); /* Adjust height to account for footer */
  width: 100%;
}

.pac-container {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-family: Arial, sans-serif;
  font-size: 14px;
  margin-top: 2px;
  padding: 5px;
}

.pac-item {
  cursor: pointer;
  padding: 5px;
}

.pac-item:hover {
  background-color: #f0f0f0;
}

.info-window {
  padding: 12px;
  max-width: 250px;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
}

.info-window h3 {
  margin: 0 0 8px 0;
  font-size: 14px;
  font-weight: 600;
}

.info-window p {
  margin: 6px 0;
}

.info-window p:last-child {
  margin-bottom: 0;
}

.hotel-prompt {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  font-weight: bold;
  z-index: 1000;
}

.app-header {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  max-width: 300px;
}

.app-title {
  font-size: 20px;
  margin: 0 0 5px 0;
  color: #333;
}

.app-description {
  font-size: 14px;
  margin: 0;
  color: #666;
}

@media (max-width: 768px) {
  .controls-overlay {
    flex-direction: column;
    align-items: center;
    padding: 15px;
    top: 10px;
  }

  .date-selector {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }

  .date-buttons {
    justify-content: center;
  }

  .hotel-search {
    width: 100%;
    justify-content: center;
  }

  .hotel-search input {
    /* width: 100%; */
    /* max-width: 300px; */
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .controls-overlay {
    width: 95%;
    padding: 10px;
  }

  .date-buttons button {
    padding: 4px 8px;
    font-size: 11px;
    margin-bottom: 5px;
  }

  .hotel-search input {
    /* width: 95%; */
    width: 100%;
    font-size: 14px;
  }
}

.footer {
  text-align: center;
  padding: 8px 5px;
  background: #333;
  color: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
  font-size: 12px;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.3);
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content p {
  margin: 3px 0;
}

.footer-content a {
  color: #1e90ff;
  text-decoration: none;
  font-weight: bold;
}

.footer-content a:hover {
  text-decoration: underline;
}
